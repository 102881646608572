import { parseSearchState, SearchProvider } from '@faststore/sdk'
import { useSession } from '@plurix/ecom-pages/src/sdk/session'
import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { ITEMS_PER_PAGE } from '@plurix/ecom-pages/src/constants'
import { applySearchState } from '@plurix/ecom-pages/src/sdk/search/state'
import type { PageProps } from 'gatsby'
import type {
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables,
} from '@generated/graphql'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import SROnly from '@plurix/ecom-pages/src/components/ui/SROnly'
import { mark } from '@plurix/ecom-pages/src/sdk/tests/mark'
import type { SearchState } from '@faststore/sdk'
import { NpmProductListingPage } from 'src/local_modules'
import { getClubName } from '@plurix/ecom-pages/src/utils/formatAccountName'
import { account } from '../../store.config'

export type Props = PageProps<
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables
>

const useSearchParams = ({ href }: Location) => {
  const [params, setParams] = useState<SearchState | null>(null)
  const [isClubSearch, setIsClubSearch] = useState<boolean>(false)

  useEffect(() => {
    const url = new URL(href)
    const parsedSearchState = parseSearchState(url)

    if (href.includes('clubProducts') && !isClubSearch) {
      setIsClubSearch(true)
    }

    if (isClubSearch) {
      parsedSearchState.selectedFacets = [
        ...parsedSearchState.selectedFacets,
        { key: 'preco2', value: 'true' },
      ]
    }

    setParams(parsedSearchState)
  }, [href, isClubSearch])

  return params
}

function Page(props: Props) {
  const {
    data: { site },
    location,
  } = props

  const { locale, search } = useSession()
  const searchParams = useSearchParams(props.location)
  let title = 'Search Results'
  const { href } = props.location
  const breadcrumb = []
  const clubName = getClubName(account)

  if (!searchParams) {
    return null
  }

  if (href.includes('clubProducts') && breadcrumb.length === 0) {
    title = 'Ofertas clube'
    breadcrumb.push({
      item: '/?clubProducts',
      name: clubName,
      position: 1,
    })
  }

  const SearchProviderOnChange = (url: URL) => {
    if (href.includes('clubProducts')) {
      const newSearch = `?clubProducts&preco2=true${url.search.replace(
        '?',
        '&'
      )}`

      const array = newSearch.split('&')
      const newArray = array.filter(
        (elem, pos, self) => self.indexOf(elem) === pos
      )

      url.search = newArray.join('&')
    }

    applySearchState(url, search)
  }

  return (
    <SearchProvider
      onChange={(url) => SearchProviderOnChange(url)}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      <GatsbySeo
        noindex
        language={locale}
        title={title}
        description={site?.siteMetadata?.description ?? ''}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />

      {/*
        WARNING: Do not import or render components from any
        other folder than '../components/sections' in here.

        This is necessary to keep the integration with the CMS
        easy and consistent, enabling the change and reorder
        of elements on this page.

        If needed, wrap your component in a <Section /> component
        (not the HTML tag) before rendering it here.
      */}
      <SROnly as="h1" text={title} />

      <NpmProductListingPage
        title={title}
        slug={location.search}
        itemListElement={breadcrumb}
      />
    </SearchProvider>
  )
}

export const querySSG = graphql`
  query SearchPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`

Page.displayName = 'Page'

export default mark(Page)
